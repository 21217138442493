<template>
  <Popup
    :title="title"
    :page="page"
    :name="name"
    :parent="parent"
    :width="width"
    :height="height"
    class="search-person default"
  >
    <div class="top">
      <CivilianSearchInput
        ref="searchInput"
        @selectedCivilian="selectCivilian($event)"
      />
    </div>
    <div
      class="_content col-pc-10 col-lgmobile-10 col-margin-auto"
      v-if="civilian"
    >
      <div class="_row col-pc-12 col-lgmobile-12">
        <div class="driver_license">
          <div
            class="license_frame san_andreas"
            :uk-tooltip="
              checkPermissions()(permissions, 'admin')
                ? `title: ${civilian.creator.email}; cls: uk-active`
                : `title: ; cls: col-display-none`
            "
          >
            <div class="license_content col-all-12">
              <div class="row col-all-12">
                <div class="license_name col-all-6">
                  <h3>{{ $t('search-person_component.driver-license') }}</h3>
                </div>
                <div class="license_status col-all-6">
                  <p>{{ $t('search-person_component.status.' + civilian.driverLicense.status) }}</p>
                </div>
              </div>
              <div class="row">
                <div class="image col-all-4">
                  <div class="frame">
                    <img src="//cdn.leafcad.com/images/other/user_image_unknown.png" />
                  </div>
                </div>
                <div class="license_description col-all-8">
                  <div class="full_name">
                    <div class="_text">
                      <p>{{ `${civilian.firstName} ${civilian.lastName}` }}</p>
                    </div>
                  </div>
                  <div class="birthday">
                    <div class="_name">
                      <p>{{ $t('search-person_component.driver-birthday') }}:</p>
                    </div>
                    <div class="_text">
                      <p>{{ moment(civilian.birthDate).format('YYYY-MM-DD') }}</p>
                    </div>
                  </div>
                  <div class="address">
                    <div class="_text">
                      <!-- <p class="street_number">{{ civilian.streetNumber }}</p>
                      <p
                        class="city_details"
                      >{{ civilian.city }}, {{ civilian.state }}, {{ civilian.country }}</p>
                      <p class="postal_code">{{ civilian.postalCode }}</p>-->
                    </div>
                  </div>
                  <div class="row">
                    <div class="columns col-all-12">
                      <div class="column col-all-6">
                        <div class="license_classes">
                          <div class="_name">
                            <p>{{ $t('search-person_component.driver-classes') }}:</p>
                          </div>
                          <div class="_text">
                            <span
                              v-for="(drivingClass, index) in civilian.driverLicense.classes"
                              :key="index"
                            >{{
                              drivingClass.name
                            }}</span>
                            <span v-if="civilian.driverLicense.classes.length === 0">
                              {{ $t('search-person_component.none') }}
                            </span>
                          </div>
                        </div>
                        <div class="license_conditions">
                          <div class="_name">
                            <p>{{ $t('search-person_component.driver-conditions') }}:</p>
                          </div>
                          <div class="_text">
                            <span
                              v-for="(condition, index) in civilian.driverLicense.conditions"
                              :key="index"
                            >{{
                              condition.name
                            }}</span>
                            <span v-if="civilian.driverLicense.conditions.length === 0">
                              {{ $t('search-person_component.none') }}
                            </span>
                          </div>
                        </div>
                        <div class="license_eyes_color">
                          <div class="_name">
                            <p>{{ $t('search-person_component.driver-eyes') }}:</p>
                          </div>
                          <div class="_text">
                            <p>{{ $t('eye-color-attribute.' + civilian.eyeColor) }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="column col-all-6">
                        <div class="gender">
                          <div class="_name">
                            <p>{{ $t('search-person_component.driver-gender') }}:</p>
                          </div>
                          <div class="_text">
                            <p v-if="civilian.gender === 'MALE'">{{ $t('gender-attribute.MALE') }}</p>
                            <p v-if="civilian.gender === 'FEMALE'">{{ $t('gender-attribute.FEMALE') }}</p>
                            <p v-if="civilian.gender === 'UNDEFINED'">{{ $t('gender-attribute.UNKNOWN') }}</p>
                          </div>
                        </div>
                        <div class="height">
                          <div class="_name">
                            <p>{{ $t('search-person_component.driver-height') }}:</p>
                          </div>
                          <div class="_text">
                            <p>{{ civilian.height }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="signature col-all-4">
                  <p class="signature">{{ `${civilian.firstName} ${civilian.lastName}` }}</p>
                </div>
                <div class="person_description col-all-8">
                  <div class="columns col-all-12">
                    <div class="column col-all-6">
                      <div class="license_issued_date">
                        <div class="_name">
                          <p>{{ $t('search-person_component.issued') }}:</p>
                        </div>
                        <div class="_text">
                          <p>
                            {{
                              civilian.driverLicense.status === 'NONE' || !civilian.driverLicense.status
                                ? $t('search-person_component.not-applicable-abrv')
                                : moment(civilian.driverLicense.issuedAt).format('YYYY-MM-DD')
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="column col-all-6">
                      <div class="license_expiration_date">
                        <div class="_name">
                          <p>{{ $t('search-person_component.expires') }}:</p>
                        </div>
                        <div class="_text">
                          <p>
                            {{
                              civilian.driverLicense.status === 'NONE' || !civilian.driverLicense.status
                                ? $t('search-person_component.not-applicable-abrv')
                                : moment(civilian.driverLicense.expiresAt).format('YYYY-MM-DD')
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="_row boxes col-pc-12 col-lgmobile-12">
        <ul
          class="grid col-pc-12 col-lgmobile-12"
          uk-accordion="multiple: true"
        >
          <li class="column notes">
            <a
              href="#"
              class="uk-accordion-title title"
            >
              <span class="uk-badge">{{ civilianNotesCount }}</span>
              <h3>{{ $t('search-person_component.note') }}(s)</h3>
            </a>
            <div class="uk-accordion-content list">
              <NotesSection
                ref="notesSection"
                :civilianId="civilian.id"
                @notesCount="civilianNotesCount = $event"
              />
            </div>
          </li>
          <li class="column vehicles">
            <a
              href="#"
              class="uk-accordion-title title"
            >
              <span class="uk-badge">{{ civilian.vehicles.length }}</span>
              <h3>{{ $t('search-person_component.vehicle') }}(s)</h3>
            </a>
            <div class="uk-accordion-content list">
              <ul class="vehicles-list">
                <li
                  class="no_result"
                  v-if="civilian.vehicles.length === 0"
                >
                  <p>{{ $t('search-person_component.no-vehicle') }}</p>
                </li>
                <li
                  v-for="vehicle in civilian.vehicles"
                  :key="vehicle.id"
                  class="col-all-12"
                >
                  <div class="flexbox">
                    <div class="left col-all-12">
                      <div class="li-column col-f">
                        <div class="subtitle">
                          <p class="plate title-orange">{{ vehicle.plate }}</p>
                        </div>
                        <div class="model col-f">
                          <p class="i-title">{{ $t('search-person_component.model') }}:</p>
                          <p
                            class="i-text"
                            :title="vehicle.model"
                          >{{ vehicle.model }}</p>
                        </div>
                      </div>
                      <div class="li-column col-f">
                        <div class="color col-f">
                          <p class="i-title">{{ $t('search-person_component.color') }}:</p>
                          <p class="i-text">{{ $t('car-color-attribute')[vehicle.color] }}</p>
                        </div>
                        <div class="insurance col-f">
                          <p class="i-title">{{ $t('search-person_component.insurance') }}:</p>
                          <p
                            class="i-text"
                            :class="{
                              success: vehicle.insurance == 'VALID',
                              danger: ['EXPIRED', 'REVOKED', 'NONE'].includes(vehicle.insurance)
                            }"
                          >
                            {{ $t('car-insurance-attribute')[vehicle.insurance] }}
                          </p>
                        </div>
                        <div class="registration col-f">
                          <p class="i-title">{{ $t('search-person_component.registration') }}:</p>
                          <p
                            class="i-text"
                            :class="{
                              success: vehicle.registration == 'VALID',
                              danger: ['EXPIRED', 'NONE'].includes(vehicle.registration),
                              warning: vehicle.registration == 'SUSPENDED'
                            }"
                          >
                            {{ $t('car-registration-attribute')[vehicle.registration] }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="right"></div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li class="column arrestations">
            <a
              href="#"
              class="uk-accordion-title title"
            >
              <span class="uk-badge">{{ civilian.arrestations.length }}</span>
              <h3>{{ $t('search-person_component.arrestation') }}(s)</h3>
            </a>
            <div class="uk-accordion-content list">
              <ul class="arrestations-list">
                <li
                  class="no_result"
                  v-if="civilian.arrestations.length === 0"
                >
                  <p>{{ $t('search-person_component.no-arrestation') }}</p>
                </li>

                <li
                  v-for="arrestation in civilian.arrestations"
                  :key="arrestation.id"
                  class="col-all-12"
                >
                  <div class="flexbox col-pc-12 col-lgmobile-12">
                    <div class="li-column col-all-12 col-f">
                      <div class="li-row col-all-4">
                        <div class="subtitle">
                          <p class="title-orange">
                            {{ moment(arrestation.arrestationDate).format('YYYY-MM-DD') }}
                            {{ moment(arrestation.arrestationDate).format('hh:mm:ss') }}
                          </p>
                        </div>
                        <div class="charges col-f">
                          <p>{{ arrestation.offences.length }} {{ $t('search-person_component.charge') }}(s)</p>
                        </div>
                      </div>
                      <div class="li-row col-all-4 col-f filedby">
                        <p class="i-title">{{ $t('search-person_component.filed-by') }}:</p>
                        <p
                          class="i-text"
                          :title="arrestation.issuer.firstName + ' ' + arrestation.issuer.lastName"
                        >
                          {{ arrestation.issuer.firstName }} {{ arrestation.issuer.lastName }}
                        </p>
                      </div>
                      <div class="li-row col-all-4 more-details">
                        <button
                          @click="$emit('openArrest', arrestation.id)"
                          class="secondary-full"
                        >
                          {{ $t('search-person_component.more-details') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li class="column citations">
            <a
              href="#"
              class="uk-accordion-title title"
            >
              <span class="uk-badge">{{ civilian.citations.length }}</span>
              <h3>{{ $t('search-person_component.citation') }}(s)</h3>
            </a>
            <div class="uk-accordion-content list">
              <ul class="citations-list">
                <li
                  class="no_result"
                  v-if="civilian.citations.length === 0"
                >
                  <p>{{ $t('search-person_component.no-citation') }}</p>
                </li>
                <li
                  v-for="citation in civilian.citations"
                  :key="citation.id"
                  class="col-all-12"
                >
                  <div class="flexbox col-pc-12 col-lgmobile-12">
                    <div class="li-column col-all-12 col-f">
                      <div class="li-row col-all-4">
                        <div class="subtitle">
                          <p class="title-orange">
                            {{ moment(citation.violationDate).format('YYYY-MM-DD') }}
                            {{ moment(citation.violationDate).format('hh:mm:ss') }}
                          </p>
                        </div>
                        <div class="charges col-f">
                          <p>{{ citation.offences.length }} {{ $t('search-person_component.offence') }}(s)</p>
                        </div>
                      </div>
                      <div class="li-row col-all-4">
                        <div class="filedby col-f">
                          <p class="i-title">{{ $t('search-person_component.filed-by') }}:</p>
                          <p
                            class="i-text"
                            :title="citation.issuer.firstName + ' ' + citation.issuer.lastName"
                          >
                            {{ citation.issuer.firstName }} {{ citation.issuer.lastName }}
                          </p>
                        </div>
                        <div class="type col-f">
                          <p class="i-title">{{ $t('search-person_component.type') }}:</p>
                          <p class="i-text">
                            {{
                              citation.vehicle
                                ? $t('search-person_component.traffic')
                                : $t('search-person_component.other')
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="li-row col-all-4 more-details">
                        <button
                          @click="$emit('openCitation', citation.id)"
                          class="secondary-full"
                        >
                          {{ $t('search-person_component.more-details') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <div class="column firearms">
            <div class="title">
              <h3>{{ $t('search-person_component.firearm') }}(s)</h3>
            </div>
            <div class="list">
              <ul>
                <li class="coming_soon">
                  <p>{{ $t('search-person_component.coming-soon') }}</p>
                </li>
              </ul>
            </div>
          </div>
        </ul>
      </div>
      <div v-if="checkPermissions()(permissions, 'admin')">
        <button
          class="danger-full col-mobile-12"
          @click="deleteCivilian"
        >{{ $t('actions.delete') }}</button>
      </div>
    </div>
    <div v-else>
      <p>{{ $t('search-person_component.search-a-person') }}</p>
    </div>
  </Popup>
</template>
<style lang="less" scoped src="@/assets/less/components/popup-styles.less"></style>
<style lang="less" scoped src="@/assets/less/components/driver_license.less"></style>

<script>
import { mapGetters } from 'vuex'
import Popup from '@/components/Popup'
import CivilianSearchInput from '@/components/CivilianSearchInput'
import ClickOutside from 'vue-click-outside'
import NotesSection from './searchPerson/NotesSection'

export default {
  components: {
    Popup,
    CivilianSearchInput,
    NotesSection
  },
  data() {
    return {
      civilian: null,
      civilianNotesCount: 0
    }
  },
  props: {
    page: String,
    name: String,
    title: String,
    width: Number,
    height: Number,
    parent: Boolean
  },
  computed: {
    permissions() {
      return this.$store.state.selection.community ? this.$store.state.selection.community.permissionsValue : 0
    }
  },
  methods: {
    ...mapGetters('auth', ['checkPermissions']),
    openCivilian(civilianId) {
      this.$refs.searchInput.selectCivilian(civilianId)
    },
    selectCivilian(civilian) {
      if (this.civilian && this.civilian.id === civilian.id) {
        this.$refs.notesSection.forceRefetch()
      }

      this.civilian = civilian
    },
    deleteCivilian() {
      this.$swal({
        title: this.$t('civilian.are-you-sure'),
        text: this.$t('civilian.you-wont-be-able-to-revert'),
        type: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('actions.delete'),
        cancelButtonText: this.$t('actions.cancel')
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch('civilian/deleteCivilian', this.civilian)
            .then(response => {
              this.civilian = null
              this.$refs.searchInput.clearInput()

              this.$notify({
                type: 'success',
                title: this.$t('notifications.save.title'),
                text: this.$t('notifications.save.text')
              })
            })
            .catch(error => {
              this.$store.dispatch('errorManager/showError', { error: error })
            })
        }
      })
    }
  },
  directives: {
    ClickOutside
  }
}
</script>
