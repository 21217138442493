<template>
  <div class="responder">
    <InformationBanner></InformationBanner>
    <UnitPopup
      :active="isUnitPopupOpen"
      :showVehicle="editVehicleInUnitPopup"
      :willRedirectAfterExit="true"
      @close="isUnitPopupOpen = false"
      @saved="isUnitPopupOpen = false"
      @exitedUnit="$router.push({ name: 'characters', query: { skipLeaveConfirmation: true } })"
    />
    <div class="_container">
      <!-- This slot will put popups on the top -->
      <slot name="popups"></slot>
      <div class="top">
        <div class="grid">
          <div class="column sign-out">
            <button class="orange-full v-step-exit-button" @click="$router.push({ name: 'characters' })">
              <span uk-icon="icon: sign-out"></span>
            </button>
          </div>
          <div class="column other left">
            <!-- TODO:<button class="secondary-full">
            <i class="fas fa-sticky-note"></i>
            </button>-->
          </div>
          <div class="column logo">
            <img src="//cdn.leafcad.com/images/logos/leafcad-logos/Logo%20LeafCAD.png" alt="community-logo" />
          </div>
          <div class="column other right"></div>
          <div class="column duty-board">
            <div class="version">
              <p class="orange">{{ $t('public-version') }}</p>
            </div>
            <div class="flexbox col-pc-10 col-lgmobile-10 v-step-unit-configuration">
              <div class="row badge-number">
                <div class="title col-pc-5 col-lgmobile-5">
                  <p>{{ $t('responder_layout.badge-number') }}:</p>
                </div>
                <div class="text col-pc-7 col-lgmobile-7">
                  <label>
                    <div v-if="!edit.badgeNumber" class="col-pc-12 col-lgmobile-12" @click="editUnit">
                      <span>{{ $store.state.selection.character.officer.badgeNumber }}</span>
                      <span class="edit-icon uk-icon" uk-icon="icon: pencil"></span>
                    </div>
                  </label>
                </div>
              </div>
              <!-- TODO: <div class="row division">
              <div class="title col-pc-5 col-lgmobile-5">
                <p>Division:</p>
              </div>
              <div class="text col-pc-7 col-lgmobile-7">
                <label>
                  <div v-if="!edit.division" class="col-pc-12 col-lgmobile-12" @click="editDivision">
                    <span>{{ currentDivision }}</span>
                    <span class="edit-icon uk-icon" uk-icon="icon: pencil"></span>
                  </div>
                </label>
              </div>
              </div>-->
              <div class="row vehicle">
                <div class="title col-pc-5 col-lgmobile-5">
                  <p>{{ $t('responder_layout.vehicle') }}:</p>
                </div>
                <div class="text col-pc-7 col-lgmobile-7">
                  <label>
                    <div
                      v-if="!edit.vehicle && $store.state.selection.unit"
                      class="col-pc-12 col-lgmobile-12"
                      @click="editUnit"
                    >
                      <span>{{ $store.state.selection.unit.number }}</span>
                      <span class="edit-icon uk-icon" uk-icon="icon: pencil"></span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Main slot for the rest of the page -->
      <slot></slot>
      <CookieBanner></CookieBanner>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/dashboards/responders-interfaces.less"></style>

<script>
import InformationBanner from '@/components/InformationBanner'
import CookieBanner from '@/components/CookieBanner'
import UnitPopup from '@/components/UnitPopup'

export default {
  name: 'ResponderLayoutComponent',
  components: {
    InformationBanner,
    CookieBanner,
    UnitPopup
  },
  data() {
    return {
      edit: {
        badgeNumber: false,
        division: false,
        vehicle: false
      },
      isUnitPopupOpen: false,
      editVehicleInUnitPopup: false
    }
  },
  methods: {
    editUnit() {
      this.editVehicleInUnitPopup = true
      this.isUnitPopupOpen = true
    }
  }
}
</script>
