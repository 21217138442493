<template>
  <div
    class="pagination"
    v-if="totalPages > 1"
  >
    <ul
      class="uk-pagination uk-flex-center"
      uk-margin
    >
      <li v-if="hasPrev()"><a
          href="#"
          @click.prevent="changePage(prevPage)"
        ><span uk-pagination-previous></span></a></li>
      <li
        v-if="hasFirst()"
        :class="{ 'uk-active': current == 1 }"
      ><a
          href="#"
          @click.prevent="changePage(1)"
        >1</a></li>
      <li
        class="uk-disabled"
        v-if="hasFirst()"
      ><span>...</span></li>
      <li
        v-for="page in pages"
        :key="page"
        :class="{ 'uk-active': current == page }"
      ><a
          href="#"
          @click.prevent="changePage(page)"
        >{{ page }}</a></li>
      <li
        class="uk-disabled"
        v-if="hasLast()"
      ><span>...</span></li>
      <li
        v-if="hasLast()"
        :class="{ 'uk-active': current == totalPages }"
      ><a
          href="#"
          @click.prevent="changePage(totalPages)"
        >{{ totalPages }}</a></li>
      <li v-if="hasNext()"><a
          href="#"
          @click.prevent="changePage(nextPage)"
        ><span uk-pagination-next></span></a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    current: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 2
    },
    pageRange: {
      type: Number,
      default: 2
    }
  },
  methods: {
    hasFirst() {
      return this.rangeStart !== 1
    },
    hasLast() {
      return this.rangeEnd < this.totalPages
    },
    hasPrev() {
      return this.current > 1
    },
    hasNext() {
      return this.current < this.totalPages
    },
    changePage(page) {
      console.log(page)
      if (page > 0 && page <= this.totalPages) {
        this.$emit('pageChanged', page)
      }
    }
  },
  computed: {
    pages() {
      let pages = []
      for (var i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i)
      }
      return pages
    },
    rangeStart() {
      let start = this.current - this.pageRange
      return start > 0 ? start : 1
    },
    rangeEnd() {
      let end = this.current + this.pageRange
      return end < this.totalPages ? end : this.totalPages
    },
    totalPages() {
      return Math.ceil(this.total / this.perPage)
    },
    nextPage() {
      return this.current + 1
    },
    prevPage() {
      return this.current - 1
    }
  }
}
</script>

<style lang="less" scoped>
</style>
