<template>
  <Popup
    :page="page"
    :name="name"
    :title="title"
    :parent="parent"
    :width="width"
    :height="height"
    class="search-vehicle default"
  >
    <div class="top">
      <VehicleSearchInput @selectedVehicle="selectVehicle($event)" />
    </div>
    <div
      class="_content col-pc-10 col-lgmobile-10 col-margin-auto"
      v-if="vehicle"
    >
      <div class="owner row col-pc-12 col-lgmobile-12">
        <div class="title col-pc-6 col-lgmobile-6">
          <p>{{ $t('search-vehicle_component.vehicle-owner') }}:</p>
        </div>
        <div class="text col-pc-6 col-lgmobile-6">
          <a @click="$emit('searchCivilian', vehicle.owner.id)">{{ `${vehicle.owner.firstName} ${vehicle.owner.lastName}` }}</a>
        </div>
      </div>
      <div class="model row col-pc-12 col-lgmobile-12">
        <div class="title col-pc-6 col-lgmobile-6">
          <p>{{ $t('search-vehicle_component.vehicle-model-and-color') }}:</p>
        </div>
        <div class="text col-pc-6 col-lgmobile-6">
          <p v-if="$i18n.locale === 'fr'">{{ vehicle.model }} {{ $t('car-color-attribute')[vehicle.color] }}</p>
          <p v-if="$i18n.locale === 'en'">{{ $t('car-color-attribute')[vehicle.color] }} {{ vehicle.model }}</p>
        </div>
      </div>
      <div class="insurance row col-pc-12 col-lgmobile-12">
        <div class="title col-pc-6 col-lgmobile-6">
          <p>{{ $t('search-vehicle_component.vehicle-insurance') }}:</p>
        </div>
        <div class="text col-pc-6 col-lgmobile-6">
          <p
            class="success"
            v-if="vehicle.insurance === 'VALID'"
          >{{ $t('search-vehicle_component.valid') }}</p>
          <p
            class="warning"
            v-if="vehicle.insurance === 'EXPIRED'"
          >{{ $t('search-vehicle_component.expired') }}</p>
          <p
            class="danger"
            v-if="vehicle.insurance === 'REVOKED'"
          >{{ $t('search-vehicle_component.revoked') }}</p>
          <p
            class="danger"
            v-if="vehicle.insurance === 'NONE'"
          >{{ $t('search-vehicle_component.none') }}</p>
        </div>
      </div>
      <div class="registration row col-pc-12 col-lgmobile-12">
        <div class="title col-pc-6 col-lgmobile-6">
          <p>{{ $t('search-vehicle_component.vehicle-registration') }}:</p>
        </div>
        <div class="text col-pc-6 col-lgmobile-6">
          <p
            class="success"
            v-if="vehicle.registration === 'VALID'"
          >{{ $t('search-vehicle_component.valid') }}</p>
          <p
            class="danger"
            v-if="vehicle.registration === 'SUSPENDED'"
          >
            {{ $t('search-vehicle_component.suspended') }}
          </p>
          <p
            class="warning"
            v-if="vehicle.registration === 'EXPIRED'"
          >{{ $t('search-vehicle_component.expired') }}</p>
          <p
            class="danger"
            v-if="vehicle.registration === 'REVOKED'"
          >{{ $t('search-vehicle_component.revoked') }}</p>
          <p
            class="danger"
            v-if="vehicle.registration === 'NONE'"
          >{{ $t('search-vehicle_component.none') }}</p>
        </div>
      </div>
      <div class="plate row col-all-12">
        <div class="plate_frame san_andreas">
          <div class="plate_top">
            <p>{{ $t('search-vehicle_component.san-andreas') }}</p>
          </div>
          <div class="plate_content">
            <div class="plate_number">
              <h2>{{ vehicle.plate }}</h2>
            </div>
            <div class="plate_slogan">
              <div class="line"></div>
              <p>{{ $t('search-vehicle_component.the-golden-state') }}</p>
              <div class="line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p>{{ $t('search-vehicle_component.search-a-vehicle') }}</p>
    </div>
    <!-- TODO: BOLOs -> <div class="wanted" v-if="vehicle && vehicle.wanted">
      <h3>{{ $t('search-vehicle_component.vehicle-wanted') }}</h3>
    </div>-->
  </Popup>
</template>
<style lang="less" scoped src="@/assets/less/components/popup-styles.less"></style>
<style lang="less" scoped src="@/assets/less/components/plate.less"></style>

<script>
import Popup from '@/components/Popup'
import VehicleSearchInput from '@/components/VehicleSearchInput'
import ClickOutside from 'vue-click-outside'

export default {
  components: {
    Popup,
    VehicleSearchInput
  },
  data() {
    return {
      vehicle: null
    }
  },
  props: {
    page: String,
    name: String,
    title: String,
    width: Number,
    height: Number,
    parent: Boolean
  },
  methods: {
    selectVehicle: function(vehicle) {
      this.vehicle = vehicle
    }
  },
  directives: {
    ClickOutside
  }
}
</script>
