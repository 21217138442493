<template>
  <Popup
    :title="title"
    :page="page"
    :name="name"
    :parent="parent"
    :width="width"
    :height="height"
    class="penal-code default"
  >
    <div class="top">
      <PenalCodeSearchInput @selectedCivilian="selectCivilian($event)" />
    </div>
    <div class="_content col-pc-10 col-lgmobile-10 col-margin-auto">
      <div class="_row articles-list">
        <form>
          <ul class="grid col-pc-12 col-lgmobile-12">
            <li class="article">
              <div class="accordion-tab-container">
                <CustomInput
                  class="custom-input primary"
                  type="checkbox"
                  name="article"
                  v-if="articlesAreSelectable"
                ></CustomInput>
                <div class="accordion-tab noselect col-all-12" @click="articleDropdownOpen = !articleDropdownOpen">
                  <div class="accordion-tab-title col-all-12">
                    <h3 title="(Article 92) Possession non autorisée d'une arme à feu: infraction délibérée">
                      (Article 92) Possession non autorisée d'une arme à feu: infraction délibérée
                    </h3>
                  </div>
                  <div class="accordion-tab-icon col-all-auto">
                    <div class="article-icon">
                      <transition name="icon-rotation">
                        <i
                          :class="{
                            'fa fa-plus': articleDropdownOpen === false,
                            'fa fa-minus': articleDropdownOpen
                          }"
                        ></i>
                      </transition>
                    </div>
                  </div>
                </div>
              </div>
              <transition name="accordion">
                <div class="accordion-content" v-if="articleDropdownOpen">
                  <div class="article-description">
                    <h3 class="orange uk-text-uppercase article-description-title">
                      {{ $t('penal-code_component.article-description.title') }}
                    </h3>
                    <p class="article-description-text">
                      94 (1) Sous réserve des paragraphes (3) et (4), commet une infraction quiconque occupe un véhicule
                      automobile où il sait que se trouvent une arme à feu prohibée, une arme à feu à autorisation
                      restreinte, une arme à feu sans restriction, une arme prohibée, une arme à autorisation
                      restreinte, un dispositif prohibé — autre qu’une réplique — ou des munitions prohibées sauf si :
                      a) dans le cas d’une arme à feu prohibée, d’une arme à feu à autorisation restreinte ou d’une arme
                      à feu sans restriction : (i) soit celui-ci ou tout autre occupant du véhicule est titulaire d’un
                      permis qui l’autorise à l’avoir en sa possession et, s’il s’agit d’une arme à feu prohibée ou
                      d’une arme à feu à autorisation restreinte, est également titulaire de l’autorisation et du
                      certificat d’enregistrement afférents, (ii) soit celui-ci avait des motifs raisonnables de croire
                      qu’un autre occupant du véhicule était titulaire d’un permis autorisant ce dernier à l’avoir en sa
                      possession et, s’il s’agit d’une arme à feu prohibée ou d’une arme à feu à autorisation
                      restreinte, était également titulaire de l’autorisation et du certificat d’enregistrement
                      afférents, (iii) soit celui-ci avait des motifs raisonnables de croire qu’un autre occupant du
                      véhicule ne pouvait pas être reconnu coupable d’une infraction à la présente loi, en raison des
                      articles 117.07 à 117.1 ou des dispositions de toute autre loi fédérale; b) dans le cas d’une arme
                      prohibée, d’une arme à autorisation restreinte, d’un dispositif prohibé ou de munitions prohibées
                      : (i) soit celui-ci ou tout autre occupant du véhicule est titulaire d’une autorisation ou d’un
                      permis qui l’autorise à les transporter, (ii) soit celui-ci avait des motifs raisonnables de
                      croire qu’un autre occupant du véhicule était titulaire d’une autorisation ou d’un permis qui
                      l’autorisait à les transporter ou que ce dernier ne pouvait pas être reconnu coupable d’une
                      infraction à la présente loi, en raison des articles 117.07 à 117.1 ou des dispositions de toute
                      autre loi fédérale.
                    </p>
                  </div>
                  <div class="article-punishment">
                    <h3 class="orange uk-text-uppercase article-punishment-title">
                      {{ $t('penal-code_component.article-punishment.title') }}
                    </h3>
                    <p class="article-punishment-text">
                      (2) Quiconque commet l’infraction prévue au paragraphe (1) est coupable : <br />a) soit d’un acte
                      criminel passible d’un emprisonnement maximal de dix ans; <br />b) soit d’une infraction
                      punissable sur déclaration de culpabilité par procédure sommaire.
                    </p>
                  </div>
                </div>
              </transition>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </Popup>
</template>
<style lang="less" scoped src="@/assets/less/components/popup-styles.less"></style>

<script>
import Popup from '@/components/Popup'
import PenalCodeSearchInput from '@/components/PenalCodeSearchInput'
import ClickOutside from 'vue-click-outside'

export default {
  components: {
    Popup,
    PenalCodeSearchInput
  },
  data() {
    return {
      civilian: null,
      articleDropdownOpen: false,
      articlesAreSelectable: false
    }
  },
  props: {
    page: String,
    name: String,
    title: String,
    width: Number,
    height: Number,
    parent: Boolean
  },
  methods: {
    selectCivilian: function(civilian) {
      this.civilian = civilian
    }
  },
  directives: {
    ClickOutside
  }
}
</script>
