<template>
  <div class="broadcast-message" v-if="broadcastMessage.active && bannerType !== 'none'">
    <div class="banner uk-alert col-all-12" :class="bannerType" uk-alert>
      <div class="text col-mdmobile-10 col-smobile-10">
        <p>{{ broadcastMessage.text }}</p>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/components/information-banner.less"></style>

<script>
export default {
  name: 'BroadcastMessage',
  data() {
    return {
      broadcastMessage: {},
      audioSource: null
    }
  },
  apollo: {
    broadcastMessage: {
      query: require('@/graphql/broadcastMessage/fetchBroadcastMessage.gql'),
      update(data) {
        const broadcastMessage = data.broadcastMessage

        if (!this.audioSource && broadcastMessage.active) {
          this.toggleSound(broadcastMessage)
        }

        return broadcastMessage
      },
      subscribeToMore: {
        document: require('@/graphql/broadcastMessage/subscribeToBroadcastMessage.gql'),
        variables() {
          return {
            id: this.broadcastMessage.id
          }
        },
        updateQuery(previousResult, { subscriptionData }) {
          const broadcastMessage = subscriptionData.data.broadcastMessage

          this.stopSound()

          if (broadcastMessage.node.active) {
            this.toggleSound(broadcastMessage.node)
          }

          return {
            broadcastMessage: broadcastMessage.node
          }
        }
      }
    }
  },
  computed: {
    bannerType() {
      return this.broadcastMessage.type.toLowerCase()
    }
  },
  methods: {
    toggleSound(broadcastMessage) {
      if (broadcastMessage.sound !== 'NONE') {
        switch (broadcastMessage.sound) {
          case 'TONE_1':
            this.audioSource = new Audio('//cdn.leafcad.com/sounds/tone-1.mp3')
            break
          case 'TONE_2':
            this.audioSource = new Audio('//cdn.leafcad.com/sounds/tone-2.mp3')
            break
          case 'TONE_3':
            this.audioSource = new Audio('//cdn.leafcad.com/sounds/tone-3.mp3')
            break
          case 'RADIO_PRIORITY':
            this.audioSource = new Audio('//cdn.leafcad.com/sounds/priority-tone.mp3')
            break
        }

        if (broadcastMessage.soundRepeatInterval > 0) {
          const repeatIntervalFn = setInterval(() => {
            if (this.audioSource) {
              this.audioSource.play()
            }
          }, broadcastMessage.soundRepeatInterval * 1000)
          this.$store.dispatch('broadcastMessage/updateRepeatIntervalFn', repeatIntervalFn)
        }

        this.audioSource.play()
      } else {
        this.stopSound()
      }
    },
    stopSound() {
      if (this.audioSource) {
        this.audioSource.pause()
        this.audioSource = null
      }

      this.$store.dispatch('broadcastMessage/updateRepeatIntervalFn', null)
    }
  }
}
</script>
