<template>
  <div>
    <div
      class="note-view"
      v-if="!editing && note.id !== -1"
    >
      <div
        class="time"
        :uk-tooltip="
          'title: ' + getTimeSinceUpdatedAt()"
      >
        <p>{{ moment(note.updatedAt).format('YYYY-MM-DD') }}</p>
        <p>{{ moment(note.updatedAt).format('HH:mm') }}</p>
      </div>
      <div
        class="text col-pc-auto col-lgmobile-auto"
        :uk-tooltip="
          'title: '
            + `${$t('search-person_component.notes.written-by')} <b>${note.writtenBy.firstName} ${note.writtenBy.lastName}</b> (${moment(note.createdAt).format('YYYY-MM-DD HH:mm:ss')})`
            + `<br> ${$t('search-person_component.notes.updated-by')} <b>${note.updatedBy.firstName} ${note.updatedBy.lastName}</b> (${moment(note.updatedAt).format('YYYY-MM-DD HH:mm:ss')})`"
      >
        <p>{{ note.text }}</p>
      </div>
      <div
        class="action"
        v-if="canEdit"
      >
        <button
          class="primary"
          @click="editing = true"
        >
          {{ $t('actions.edit') }}
        </button>
      </div>
    </div>
    <div
      class="note-edit"
      v-if="editing || note.id === -1"
    >
      <textarea
        class="text uk-textarea primary"
        name="note"
        v-model="noteLocal.text"
        :placeholder="$t('search-person_component.notes.placeholder')"
      ></textarea>
      <div class="actions">
        <button
          class="danger"
          :disabled="isSaveLoading || isDeleteLoading"
          @click="deleteNote"
        >{{ $t('actions.delete') }}</button>
        <button
          class="warning"
          v-if="note.id !== -1"
          :disabled="isSaveLoading || isDeleteLoading"
          @click="editing = false"
        >{{ $t('actions.cancel') }}</button>
        <button
          class="secondary-full"
          :class="{ spinner: isSaveLoading }"
          :disabled="isSaveLoading || isDeleteLoading"
          @click="saveNote"
        >{{ $t('actions.save') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoteItem',
  data() {
    return {
      isSaveLoading: false,
      isDeleteLoading: false,
      editing: false,
      noteLocal: {}
    }
  },
  props: {
    note: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      defaultValue: false
    }
  },
  watch: {
    note: {
      handler(newValue) {
        this.noteLocal = JSON.parse(JSON.stringify(newValue))
      },
      immediate: true
    }
  },
  methods: {
    getTimeSinceUpdatedAt() {
      return this.moment(this.note.updatedAt).fromNow()
    },
    async saveNote() {
      this.isSaveLoading = true
      if (this.noteLocal.id === -1) {
        delete this.noteLocal.id
        this.noteLocal = await this.$store.dispatch('civilian/createNote', this.noteLocal)
      } else {
        const updatedNote = {
          text: this.noteLocal.text,
          updatedBy: {
            connect: { id: this.$store.state.selection.character.id }
          }
        }

        this.noteLocal = await this.$store.dispatch('civilian/updateNote', {
          civilianNoteId: this.noteLocal.id,
          civilianNote: updatedNote
        })
      }

      this.$emit('save', this.noteLocal)
      this.editing = false
      this.isSaveLoading = false
    },
    deleteNote() {
      this.isDeleteLoading = true

      if (this.noteLocal.id === -1) {
        this.$emit('delete', this.note.id)
      } else {
        this.$swal({
          title: this.$t('civilian.are-you-sure'),
          text: this.$t('civilian.you-wont-be-able-to-revert'),
          type: 'warning',
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: this.$t('actions.delete'),
          cancelButtonText: this.$t('actions.cancel')
        }).then(result => {
          if (result.value) {
            this.$store.dispatch('civilian/deleteNote', this.noteLocal.id).then(() => {
              this.$emit('delete', this.note.id)
            })
          } else {
            this.isDeleteLoading = false
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/less/global.less';

.note-view {
  //background-color: #cdcdcd;
  background-color: @black-medium;
  display: grid;
  grid-template-columns: 15% 65% 20%;
  grid-gap: 8px;

  .time {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-right: 8px;
    //border-right: 1px solid @black-medium;
    border-right: 1px solid @white-dark;

    p {
      font-weight: @font-weight-semibold;
    }
  }
  .text {
    text-align: left;
    display: flex;
    align-items: center;
  }
  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }

  p {
    margin: 0;
    color: @white-light;
    font-weight: @font-weight-medium;
    white-space: break-spaces;
  }
}

.note-edit {
  display: flex;

  .text {
    flex: 1;
    margin-right: 10px;
    height: 145px;
    resize: none;
  }
  .actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
