<template>
  <ResponderLayoutComponent>
    <template v-slot:popups>
      <SearchVehicle
        :title="$t('police.search-vehicle')"
        page="police"
        name="searchVehicle"
        :width="500"
        :height="500"
        @searchCivilian="searchCivilian($event)"
      />
      <SearchPerson
        :title="$t('police.search-person')"
        page="police"
        name="searchPerson"
        ref="searchPerson"
        :width="800"
        :height="500"
        @openArrest="openArrestationReport($event)"
        @openCitation="openViolationReport($event)"
      />
      <Violation
        :title="$t('police.violation')"
        page="police"
        name="violation"
        :width="800"
        :height="500"
        :issuer="$store.state.selection.character.id"
        action="create"
      />
      <Violation
        :title="$t('police.violation-report-viewer')"
        page="police"
        name="violation-report-viewer"
        :width="800"
        :height="500"
        :issuer="$store.state.selection.character.id"
        action="view"
        :citationId="openedCitationId"
        @close="openedCitationId = null"
      />
      <Arrestation
        :title="$t('police.arrestation')"
        page="police"
        name="arrestation"
        :width="800"
        :height="500"
        :issuer="$store.state.selection.character.id"
        action="create"
      />
      <Arrestation
        :title="$t('police.arrestation-report-viewer')"
        page="police"
        name="arrestation-report-viewer"
        :width="800"
        :height="500"
        :issuer="$store.state.selection.character.id"
        action="view"
        :arrestationId="openedArrestationId"
        @close="openedArrestationId = null"
      />
      <PenalCode
        :title="$t('police.penal-code')"
        page="police"
        name="penalCode"
        :width="800"
        :height="500"
      />
    </template>
    <div class="police terminal">
      <div class="container col-pc-12 col-lgmobile-12">
        <div class="sidebar noselect">
          <div class="list">
            <h2 class="primary">{{ $t('police.search') }}</h2>
            <ul class="v-step-search-section">
              <li
                :class="{
                  active: popupData()('police', 'searchVehicle') && popupData()('police', 'searchVehicle').active
                }"
                @click="$store.dispatch('popup/toggle', { page: 'police', popup: 'searchVehicle' })"
              >
                <div class="icon col-pc-3 col-lgmobile-3">
                  <i class="lc lc-car-search"></i>
                </div>
                <div class="text col-pc-9 col-lgmobile-displaynone">
                  <p>{{ $t('police.plate') }}</p>
                </div>
              </li>
              <li
                :class="{
                  active: popupData()('police', 'searchPerson') && popupData()('police', 'searchPerson').active
                }"
                @click="$store.dispatch('popup/toggle', { page: 'police', popup: 'searchPerson' })"
              >
                <div class="icon col-pc-3 col-lgmobile-3">
                  <i class="fas fa-id-card"></i>
                </div>
                <div class="text col-pc-9 col-lgmobile-displaynone">
                  <p>{{ $t('police.name') }}</p>
                </div>
              </li>
              <li class="disabled coming_soon">
                <div class="coming-soon">
                  <p>{{ $t('police.coming-soon') }}</p>
                </div>
                <div class="icon col-pc-3 col-lgmobile-3">
                  <i class="lc lc-gun"></i>
                </div>
                <div class="text col-pc-9 col-lgmobile-displaynone">
                  <p>{{ $t('police.firearm') }}</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="list">
            <h2 class="primary">{{ $t('police.codes') }}</h2>
            <ul>
              <li class="disabled coming_soon">
                <div class="coming-soon">
                  <p>{{ $t('police.coming-soon') }}</p>
                </div>
                <div class="icon col-pc-3 col-lgmobile-3">
                  <i class="lc lc-radio"></i>
                </div>
                <div class="text col-pc-9 col-lgmobile-displaynone">
                  <p>{{ $t('police.radio') }}</p>
                </div>
              </li>
              <!-- TODO: Re-activate penal code popup -->
              <!-- <li
                :class="{
                  active: popupData()('police', 'penalCode') && popupData()('police', 'penalCode').active
                }"
                @click="$store.dispatch('popup/toggle', { page: 'police', popup: 'penalCode' })"
              > -->
              <li class="disabled coming_soon">
                <div class="coming-soon">
                  <p>{{ $t('police.coming-soon') }}</p>
                </div>
                <div class="icon col-pc-3 col-lgmobile-3">
                  <i class="fas fa-gavel"></i>
                </div>
                <div class="text col-pc-9 col-lgmobile-displaynone">
                  <p>{{ $t('police.criminal') }}</p>
                </div>
              </li>
              <li class="disabled coming_soon">
                <div class="coming-soon">
                  <p>{{ $t('police.coming-soon') }}</p>
                </div>
                <div class="icon col-pc-3 col-lgmobile-3">
                  <i class="fas fa-car"></i>
                </div>
                <div class="text col-pc-9 col-lgmobile-displaynone">
                  <p>{{ $t('police.road') }}</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="list">
            <h2 class="primary">{{ $t('police.reports') }}</h2>
            <ul class="v-step-reports-section">
              <li
                :class="{ active: popupData()('police', 'violation') && popupData()('police', 'violation').active }"
                @click="$store.dispatch('popup/toggle', { page: 'police', popup: 'violation' })"
              >
                <div class="icon col-pc-3 col-lgmobile-3">
                  <i class="fas fa-balance-scale"></i>
                </div>
                <div class="text col-pc-9 col-lgmobile-displaynone">
                  <p>{{ $t('police.violation') }}</p>
                </div>
              </li>
              <li class="disabled coming_soon">
                <div class="coming-soon">
                  <p>{{ $t('police.coming-soon') }}</p>
                </div>
                <div class="icon col-pc-3 col-lgmobile-3">
                  <i class="fas fa-exclamation-circle"></i>
                </div>
                <div class="text col-pc-9 col-lgmobile-displaynone">
                  <p>{{ $t('police.warning') }}</p>
                </div>
              </li>
              <li class="disabled coming_soon">
                <div class="coming-soon">
                  <p>{{ $t('police.coming-soon') }}</p>
                </div>
                <div class="icon col-pc-3 col-lgmobile-3">
                  <i class="fas fa-car-crash"></i>
                </div>
                <div class="text col-pc-9 col-lgmobile-displaynone">
                  <p>{{ $t('police.incident') }}</p>
                </div>
              </li>
              <li
                :class="{ active: popupData()('police', 'arrestation') && popupData()('police', 'arrestation').active }"
                @click="$store.dispatch('popup/toggle', { page: 'police', popup: 'arrestation' })"
              >
                <div class="icon col-pc-3 col-lgmobile-3">
                  <i class="lc lc-cuff"></i>
                </div>
                <div class="text col-pc-9 col-lgmobile-displaynone">
                  <p>{{ $t('police.arrest') }}</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="list">
            <h2 class="primary">{{ $t('police.communication') }}</h2>
            <ul>
              <li class="disabled coming_soon">
                <div class="icon col-pc-3 col-lgmobile-3">
                  <i class="fas fa-hard-hat"></i>
                </div>
                <div class="text col-pc-9 col-lgmobile-displaynone">
                  <p style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                    {{ $t('police.coming-soon') }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="content police-content">
          <BroadcastMessage></BroadcastMessage>
          <div class="ad col-mdmobile-displaynone col-smobile-displaynone">
            <Adsense
              ins-style="display:inline-block;width:728px;height:90px"
              data-ad-client="ca-pub-7037140739891615"
              data-ad-slot="4424682433"
              class="col-lgmobile-displaynone col-mdmobile-displaynone col-smobile-displaynone"
            >
            </Adsense>
            <Adsense
              ins-style="display:inline-block;width:650px;height:90px"
              data-ad-client="ca-pub-7037140739891615"
              data-ad-slot="3934917212"
              class="col-pc-displaynone col-mdmobile-displaynone col-smobile-displaynone"
            >
            </Adsense>
          </div>
          <div
            class="status-changing noselect"
            v-if="unit && statuses"
          >
            <div class="wrap col-pc-11 v-step-unit-status">
              <div class="row current-status">
                <p
                  class="primary"
                  v-if="unit.onDuty"
                >
                  {{ $t('police.status') }}: {{ unit.status.display }} |
                  <span>{{ $t('police.status-changed-by')[unit.statusChangedBy] }}</span>
                </p>
                <p v-else>{{ $t('police.is-off-duty') }}</p>
              </div>
              <div class="row main-buttons col-pc-12">
                <ul>
                  <li
                    v-for="statusButton in statuses.filter(status => status.category !== 'PANIC')"
                    :key="statusButton.id"
                  >
                    <button
                      class="secondary-full"
                      :class="{
                        active: unit.status && unit.status.id === statusButton.id,
                        spinner: isStatusLoading[statusButton.id]
                      }"
                      :disabled="!unit.onDuty"
                      @click="changeStatus(statusButton)"
                    >
                      {{ statusButton.display }}
                    </button>
                  </li>
                </ul>
              </div>
              <div class="row panic-buttons">
                <ul>
                  <li
                    v-for="statusButton in statuses.filter(status => status.category === 'PANIC')"
                    :key="statusButton.id"
                  >
                    <button
                      class="danger-full"
                      :class="{ active: unit.panicEnabled }"
                      :disabled="!unit.onDuty"
                      @click="togglePanic"
                    >
                      {{ statusButton.display }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="info-board">
            <div class="wrap col-pc-11 v-step-bolo-and-call-section">
              <div class="tabs">
                <ul>
                  <li
                    class="noselect"
                    :class="{ 'is-active': activeTab == 'person_bolos' }"
                    @click="switchTab('person_bolos')"
                  >
                    <a>
                      {{ $t('police.person-bolos.title') }}
                      <span class="uk-badge">{{ personBolos.length }}</span>
                    </a>
                  </li>
                  <li
                    class="noselect"
                    :class="{ 'is-active': activeTab == 'vehicle_bolos' }"
                    @click="switchTab('vehicle_bolos')"
                  >
                    <a>
                      {{ $t('police.vehicle-bolos.title') }}
                      <span class="uk-badge">{{ vehicleBolos.length }}</span>
                    </a>
                  </li>
                  <li
                    class="noselect"
                    :class="{ 'is-active': activeTab == 'active_call' }"
                    @click="switchTab('active_call')"
                  >
                    <a>
                      {{ $t('police.active-call.title') }}
                      <span
                        v-if="call"
                        class="uk-badge"
                      >{{ $t('police.active-call.pill') }}</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="tab_content">
                <div class="wrap col-pc-12 col-lgmobile-12">
                  <div
                    class="person_bolos"
                    v-show="activeTab === 'person_bolos'"
                  >
                    <ul class="person-bolos">
                      <li
                        v-for="personBolo in personBolos"
                        :key="personBolo.id"
                        class="col-lgmobile-12"
                      >
                        <div class="row person-bolos-row col-all-12">
                          <p
                            class="title"
                            :title="
                              $t('police.person-bolos.last-update') +
                                ': ' +
                                moment(personBolo.updatedAt).format('YYYY-MM-DD HH:mm:ss')
                            "
                          >
                            {{ moment(personBolo.createdAt).format('YYYY-MM-DD HH:mm:ss') }}
                          </p>
                          <div class="columns person-bolos-columns">
                            <div class="column person-information">
                              <div class="row person-bolos-row name">
                                <div class="column title">
                                  <p>{{ $t('police.person-bolos.name') }}</p>
                                </div>
                                <div class="column text">
                                  <p :title="_.toUpper(personBolo.name)">{{ personBolo.name }}</p>
                                </div>
                              </div>
                              <div class="row person-bolos-50-50-row height-weight">
                                <div class="person-bolos-row height">
                                  <div class="column title">
                                    <p>{{ $t('police.person-bolos.height') }}</p>
                                  </div>
                                  <div class="column text">
                                    <p>{{ personBolo.height }}</p>
                                  </div>
                                </div>
                                <div class="person-bolos-row weight">
                                  <div class="column title">
                                    <p>{{ $t('police.person-bolos.weight') }}</p>
                                  </div>
                                  <div class="column text">
                                    <p>{{ personBolo.weight }}</p>
                                  </div>
                                </div>
                              </div>
                              <div class="row person-bolos-50-50-row ethnicity-gender">
                                <div class="person-bolos-row ethnicity">
                                  <div class="column title">
                                    <p>{{ $t('police.person-bolos.ethnicity') }}</p>
                                  </div>
                                  <div class="column text">
                                    <p :title="_.toUpper($t('ethnic-group-attribute')[personBolo.ethnicity])">
                                      {{ $t('ethnic-group-attribute')[personBolo.ethnicity] }}
                                    </p>
                                  </div>
                                </div>
                                <div class="person-bolos-row gender">
                                  <div class="column title">
                                    <p>{{ $t('police.person-bolos.gender') }}</p>
                                  </div>
                                  <div class="column text">
                                    <p>{{ $t('gender-attribute')[personBolo.gender] }}</p>
                                  </div>
                                </div>
                              </div>
                              <div class="row person-bolos-50-50-row eye-color-hair-color">
                                <div class="person-bolos-row height">
                                  <div class="column title">
                                    <p>{{ $t('police.person-bolos.eye-color') }}</p>
                                  </div>
                                  <div class="column text">
                                    <p :title="_.toUpper($t('eye-color-attribute')[personBolo.eyeColor])">
                                      {{ $t('eye-color-attribute')[personBolo.eyeColor] }}
                                    </p>
                                  </div>
                                </div>
                                <div class="person-bolos-row weight">
                                  <div class="column title">
                                    <p>{{ $t('police.person-bolos.hair-color') }}</p>
                                  </div>
                                  <div class="column text">
                                    <p :title="_.toUpper($t('hair-color-attribute')[personBolo.hairColor])">
                                      {{ $t('hair-color-attribute')[personBolo.hairColor] }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="column bolo-information person-bolo-information">
                              <div class="row person-bolos-row reason">
                                <div class="column title">
                                  <p>{{ $t('police.person-bolos.reason') }}</p>
                                </div>
                                <div class="column text">
                                  <p :title="_.toUpper(personBolo.reason)">{{ personBolo.reason }}</p>
                                </div>
                              </div>
                              <div class="row person-bolos-row description">
                                <div class="column title">
                                  <p>{{ $t('police.person-bolos.description') }}</p>
                                </div>
                                <div class="column text">
                                  <p>{{ personBolo.description }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <h3
                      class="empty-bolo"
                      v-if="personBolos.length === 0"
                    >{{ $t('police.person-bolos.empty') }}</h3>
                  </div>
                  <div
                    class="vehicle_bolos"
                    v-show="activeTab == 'vehicle_bolos'"
                  >
                    <ul class="vehicle-bolos">
                      <li
                        v-for="vehicleBolo in vehicleBolos"
                        :key="vehicleBolo.id"
                        class="col-lgmobile-12"
                      >
                        <div class="row vehicle-bolos-row col-all-12">
                          <p
                            class="title"
                            :title="
                              $t('police.vehicle-bolos.last-update') +
                                ': ' +
                                moment(vehicleBolo.updatedAt).format('YYYY-MM-DD HH:mm:ss')
                            "
                          >
                            {{ moment(vehicleBolo.createdAt).format('YYYY-MM-DD HH:mm:ss') }}
                          </p>
                          <div class="columns vehicle-bolos-columns">
                            <div class="column vehicle-information">
                              <div class="row vehicle-bolos-row plate">
                                <div class="column title">
                                  <p>{{ $t('police.vehicle-bolos.plate') }}</p>
                                </div>
                                <div class="column text">
                                  <p :title="_.toUpper(vehicleBolo.plate)">{{ vehicleBolo.plate }}</p>
                                </div>
                              </div>
                              <div class="row vehicle-bolos-row mode">
                                <div class="column title">
                                  <p>{{ $t('police.vehicle-bolos.model') }}</p>
                                </div>
                                <div class="column text">
                                  <p :title="_.toUpper(vehicleBolo.model)">
                                    {{ vehicleBolo.model }}
                                  </p>
                                </div>
                              </div>
                              <div class="row vehicle-bolos-row color">
                                <div class="column title">
                                  <p>{{ $t('police.vehicle-bolos.color') }}</p>
                                </div>
                                <div class="column text">
                                  <p>
                                    {{ vehicleBolo.color }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="column bolo-information vehicle-bolo-information">
                              <div class="row vehicle-bolos-row reason">
                                <div class="column title">
                                  <p>{{ $t('police.vehicle-bolos.reason') }}</p>
                                </div>
                                <div class="column text">
                                  <p :title="_.toUpper(vehicleBolo.reason)">{{ vehicleBolo.reason }}</p>
                                </div>
                              </div>
                              <div class="row vehicle-bolos-row description">
                                <div class="column title">
                                  <p>{{ $t('police.vehicle-bolos.description') }}</p>
                                </div>
                                <div class="column text">
                                  <p>{{ vehicleBolo.description }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <h3
                      class="empty-bolo"
                      v-if="vehicleBolos.length === 0"
                    >{{ $t('police.vehicle-bolos.empty') }}</h3>
                  </div>
                  <div
                    class="active_call"
                    v-show="activeTab === 'active_call'"
                  >
                    <div
                      class="call"
                      v-if="call"
                    >
                      <div class="row information-row">
                        <p class="title">{{ $t('police.active-call.informations') }}</p>
                        <div class="columns informations-columns">
                          <div class="column information-column">
                            <div class="row information-row">
                              <div class="column title">
                                <p>{{ $t('police.active-call.call-location') }}</p>
                              </div>
                              <div class="column text">
                                <p>{{ call.incidentLocation }}</p>
                              </div>
                            </div>
                            <div class="row information-row">
                              <div class="column title">
                                <p>{{ $t('police.active-call.call-type') }}</p>
                              </div>
                              <div class="column text">
                                <p>{{ call.type }}</p>
                              </div>
                            </div>
                            <div class="row information-row">
                              <div class="column title">
                                <p>{{ $t('police.active-call.call-priority') }}</p>
                              </div>
                              <div class="column text">
                                <p>{{ call.priority }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="column information-column">
                            <div class="row information-row">
                              <div class="column title">
                                <p>{{ $t('police.active-call.call-id') }}</p>
                              </div>
                              <div class="column text">
                                <p>{{ call.sequentialId }}</p>
                              </div>
                            </div>
                            <div class="row information-row">
                              <div class="column title">
                                <p>{{ $t('police.active-call.caller-name') }}</p>
                              </div>
                              <div class="column text">
                                <p>{{ call.callerName }}</p>
                              </div>
                            </div>
                            <div class="row information-row">
                              <div class="column title">
                                <p>{{ $t('police.active-call.caller-phone') }}</p>
                              </div>
                              <div class="column text">
                                <p>{{ call.callerPhone }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row description-row">
                        <p class="title">{{ $t('police.active-call.details') }}</p>
                        <div class="_wrap">
                          <div class="row">
                            <p>{{ call.description }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="columns">
                          <div class="column call-logs col-pc-6 col-lgmobile-8">
                            <p class="title">{{ $t('police.active-call.call-logs') }}</p>
                            <div
                              class="_wrap"
                              id="callLogs"
                            >
                              <div class="row">
                                <ul class="col-pc-12 col-lgmobile-12">
                                  <li
                                    v-for="log in logs"
                                    :key="log.id"
                                    :class="{ 'owned-log': isOwnLog(log) }"
                                  >
                                    <div class="time">
                                      <p :uk-tooltip="'title:' + moment(log.createdAt).format('YYYY-MM-DD HH:mm:ss')">
                                        {{ moment(log.createdAt).format('HH:mm') }}
                                      </p>
                                    </div>
                                    <div class="text col-pc-auto col-lgmobile-auto">
                                      <p v-if="log.message !== 'note'">
                                        {{ $t('call-logs-messages.' + log.message, log.messageReplacements) }}
                                      </p>
                                      <p
                                        v-if="log.message === 'note'"
                                        :uk-tooltip="
                                          log.messageReplacements.role === 'DISPATCH'
                                            ? `title: ${$t(
                                                'call-logs-messages.' + log.message + '.tooltip-dispatch',
                                                log.messageReplacements
                                              )}`
                                            : `title: ${$t(
                                                'call-logs-messages.' + log.message + '.tooltip-unit',
                                                log.messageReplacements
                                              )}`
                                        "
                                      >
                                        <b>{{
                                          $t(`call-logs-messages.${log.message}.title`, log.messageReplacements)
                                        }}</b>
                                        : {{ $t(`call-logs-messages.${log.message}.content`, log.messageReplacements) }}
                                      </p>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-f col-pc-12 col-mobile-12 input-box">
                              <CustomInput
                                name="code-input"
                                style="height: 40px;"
                                class="custom-input custom-height primary col-pc-12 col-mobile-12"
                                inputClasses="box col-pc-12 col-mobile-12"
                                type="text"
                                :placeholder="$t('police.active-call.add-note')"
                                :onlyBooleanError="true"
                                v-model="$v.callCardNote.$model"
                                :model="$v.callCardNote"
                                :error="fieldsErrors.callCardNote"
                              ></CustomInput>
                              <button
                                class="primary-full send-button"
                                style="height:40px;"
                                @click="addNoteToCall"
                              >
                                <i class="fas fa-arrow-right"></i>
                              </button>
                            </div>
                          </div>
                          <div class="column units col-pc-6 col-lgmobile-4">
                            <p class="title">{{ $t('police.active-call.units') }}</p>
                            <div class="_wrap">
                              <ul>
                                <li
                                  v-for="unit in call.units"
                                  :key="unit.id"
                                >
                                  <p>
                                    <b>{{ unit.number }}</b>&nbsp;
                                    <span :title="unit.seats.map(seat => seat.badgeNumber).join(', ')">({{ unit.seats.map(seat => seat.badgeNumber).join(', ') }})</span>
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3
                      class="not-in-a-call"
                      v-if="!call"
                    >{{ $t('police.active-call.not-in-a-call') }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="coming-soon-mobile">
        <div class="icon">
          <i class="fas fa-hard-hat"></i>
        </div>
        <div class="text">
          <p>{{ $t('police.only-on-pc-and-tablets') }}</p>
        </div>
      </div>

      <v-tour
        :name="this.$route.name"
        :steps="tourSteps"
        :callbacks="finishCallbackOnly"
        :options="translatedLabels"
      ></v-tour>
    </div>
  </ResponderLayoutComponent>
</template>
<style lang="less" scoped src="@/assets/less/dashboards/responders-interfaces.less"></style>

<script>
import ResponderLayoutComponent from '@/components/ResponderLayoutComponent'
import { mapGetters } from 'vuex'
import UnloadHelper from '@/mixins/UnloadHelper'
import EnforcePermissionsForCommunity from '@/mixins/EnforcePermissionsForCommunity'
import TourManager from '@/mixins/TourManager'
import SearchVehicle from '@/components/SearchVehicle'
import SearchPerson from '@/components/SearchPerson'
import Violation from '@/components/Violation'
import Arrestation from '@/components/Arrestation'
import BroadcastMessage from '@/components/BroadcastMessage'
import PenalCode from '@/components/PenalCode'
import { setInterval } from 'timers'

export default {
  name: 'police',
  mixins: [UnloadHelper, EnforcePermissionsForCommunity, TourManager],
  components: {
    ResponderLayoutComponent,
    SearchVehicle,
    SearchPerson,
    Violation,
    Arrestation,
    BroadcastMessage,
    PenalCode
  },
  data() {
    return {
      isStatusLoading: {},
      fieldsErrors: {},
      isOpen: false,
      activeTab: 'person_bolos',
      statuses: [],
      unit: null,
      personBolos: [],
      vehicleBolos: [],
      call: null,
      newCallAudioSource: null,
      panicAudioSource: null,
      isInCall: false,
      openedArrestationId: null,
      openedCitationId: null,
      callCardNote: '',
      tourSteps: [
        {
          target: '.v-step-unit-status',
          content: this.$t('tour.police.unit-status'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-bolo-and-call-section',
          content: this.$t('tour.police.bolo-and-call-section'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-search-section',
          content: this.$t('tour.police.search-section-1'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-search-section',
          content: this.$t('tour.police.search-section-2'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-reports-section',
          content: this.$t('tour.police.reports-section'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-unit-configuration',
          content: this.$t('tour.police.unit-configuration'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-exit-button',
          content: this.$t('tour.police.exit-button'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        }
      ]
    }
  },
  validations: {
    callCardNote: {}
  },
  apollo: {
    statuses: {
      query: require('@/graphql/police/fetchStatuses.gql'),
      variables() {
        return {
          communityId: this.$store.state.selection.community.community.id
        }
      },
      fetchPolicy: 'no-cache',
      update: function(data) {
        data.community.statuses.forEach(status => (this.isStatusLoading[status.id] = false))
        return data.community.statuses
      }
    },
    personBolos: {
      query: require('@/graphql/dispatcher/fetchPersonBolos.gql'),
      fetchPolicy: 'network-only',
      update(data) {
        this.$logRocket.debug('[Query] PersonBolos', data)
        return data.personBoloes
      },
      subscribeToMore: {
        document: require('@/graphql/dispatcher/subscribeToPersonBolos.gql'),
        updateQuery(previousResult, { subscriptionData }) {
          const personBolo = subscriptionData.data.personBolo
          this.$logRocket.debug('[Subscription] PersonBolos', subscriptionData)

          if (personBolo.mutation === 'CREATED') {
            return {
              personBoloes: [...previousResult.personBoloes, personBolo.node]
            }
          }

          if (personBolo.mutation === 'UPDATED') {
            return {
              personBoloes: previousResult.personBoloes.map(bolo =>
                bolo.id === personBolo.node.id ? personBolo.node : bolo
              )
            }
          }

          if (personBolo.mutation === 'DELETED') {
            return {
              personBoloes: previousResult.personBoloes.filter(bolo => bolo.id !== personBolo.previousValues.id)
            }
          }
        }
      }
    },
    vehicleBolos: {
      query: require('@/graphql/dispatcher/fetchVehicleBolos.gql'),
      fetchPolicy: 'network-only',
      update(data) {
        this.$logRocket.debug('[Query] VehicleBolos', data)
        return data.vehicleBoloes
      },
      subscribeToMore: {
        document: require('@/graphql/dispatcher/subscribeToVehicleBolos.gql'),
        updateQuery(previousResult, { subscriptionData }) {
          const vehicleBolo = subscriptionData.data.vehicleBolo
          this.$logRocket.debug('[Subscription] VehicleBolos', subscriptionData)

          if (vehicleBolo.mutation === 'CREATED') {
            return {
              vehicleBoloes: [...previousResult.vehicleBoloes, vehicleBolo.node]
            }
          }

          if (vehicleBolo.mutation === 'UPDATED') {
            return {
              vehicleBoloes: previousResult.vehicleBoloes.map(bolo =>
                bolo.id === vehicleBolo.node.id ? vehicleBolo.node : bolo
              )
            }
          }

          if (vehicleBolo.mutation === 'DELETED') {
            return {
              vehicleBoloes: previousResult.vehicleBoloes.filter(bolo => bolo.id !== vehicleBolo.previousValues.id)
            }
          }
        }
      }
    },
    unit: {
      query: require('@/graphql/police/fetchUnit.gql'),
      fetchPolicy: 'network-only',
      update: data => data.unit,
      variables() {
        return {
          unitId: this.$store.state.selection.unit.id
        }
      },
      subscribeToMore: {
        document: require('@/graphql/police/subscribeToUnit.gql'),
        variables() {
          return {
            unitId: this.$store.state.selection.unit.id
          }
        },
        updateQuery(previousResult, { subscriptionData }) {
          const unit = subscriptionData.data.unit

          // Receive new call (assigned)
          if (unit.node.activeCall && !this.isInCall) {
            this.playNewCallSound()
            this.isInCall = true
            this.activeTab = 'active_call'

            this.$notify({
              type: 'success',
              title: this.$t('notifications.assigned-to-call.title'),
              text: this.$t('notifications.assigned-to-call.text'),
              duration: 10000
            })
          } else if (!unit.node.activeCall && this.isInCall) {
            this.isInCall = false

            this.$notify({
              type: 'success',
              title: this.$t('notifications.unassigned-from-call.title'),
              text: this.$t('notifications.unassigned-from-call.text'),
              duration: 10000
            })
          }

          return {
            unit: subscriptionData.data.unit.node
          }
        }
      }
    },
    panicUnits: {
      query: require('@/graphql/police/fetchUnitsPanic.gql'),
      update(data) {
        let panicEnabled = false
        data.units.forEach(unit => {
          if (unit.panicEnabled) panicEnabled = true
        })

        if (panicEnabled && !this.panicAudioSource) {
          this.playPanicSound()
        }
        this.$logRocket.debug('[Query] panicUnits', data)

        return data.units
      },
      variables() {
        return {
          communityId: this.$store.state.selection.community.community.id
        }
      },
      subscribeToMore: {
        document: require('@/graphql/police/subscribeToUnitsPanic.gql'),
        variables() {
          return {
            communityId: this.$store.state.selection.community.community.id
          }
        },
        updateQuery(previousResult, { subscriptionData }) {
          const unit = subscriptionData.data.unit
          this.$logRocket.debug('[Subscription] panicUnits', subscriptionData)

          if (unit.node.panicEnabled) {
            this.$notify({
              type: 'warn',
              title: this.$t('notifications.unit-in-panic.title'),
              text: this.$t('notifications.unit-in-panic.text', { unitNumber: unit.node.number }),
              duration: -1
            })
          }

          // Enable/Disable panic sound
          if (unit.node.panicEnabled && !unit.previousValues.panicEnabled && !this.panicAudioSource) {
            this.playPanicSound()
          } else if (!unit.node.panicEnabled && unit.previousValues.panicEnabled) {
            this.stopPanicSound()
          }

          if (unit.mutation === 'CREATED') {
            return {
              units: [...previousResult.units, unit.node]
            }
          }

          if (unit.mutation === 'UPDATED') {
            return {
              units: previousResult.units.map(theUnit => (theUnit.id === unit.node.id ? unit.node : theUnit))
            }
          }

          if (unit.mutation === 'DELETED') {
            return {
              units: previousResult.units.filter(theUnit => theUnit.id !== unit.previousValues.id)
            }
          }
        }
      }
    },
    call: {
      query: require('@/graphql/dispatcher/fetchCallCard.gql'),
      fetchPolicy: 'network-only',
      update(data) {
        return data.callCard
      },
      variables() {
        return {
          id: this.unit && this.unit.activeCall ? this.unit.activeCall.id : ''
        }
      },
      subscribeToMore: {
        document: require('@/graphql/dispatcher/subscribeToCallCard.gql'),
        variables() {
          return {
            id: this.unit && this.unit.activeCall ? this.unit.activeCall.id : ''
          }
        },
        updateQuery(previousResult, { subscriptionData }) {
          // const callCard = subscriptionData.data.callCard

          return {
            callCard: subscriptionData.data.callCard.node
          }
        }
      }
    }
  },
  methods: {
    ...mapGetters('popup', ['popupData']),
    switchTab: function(newTab) {
      this.activeTab = newTab
    },
    changeStatus(status) {
      if (this.unit.status.id === status.id) {
        return
      }

      this.isStatusLoading[status.id] = true

      // TODO: i18n -> SELF
      this.$store
        .dispatch('police/updateStatus', { status, unit: this.unit, changedBy: 'SELF' })
        .then(response => {
          this.isStatusLoading[status.id] = false
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    togglePanic() {
      if (this.unit.panicEnabled && this.$store.state.selection.community.community.settings.officerCanDisablePanic) {
        this.$store.dispatch('police/updatePanic', false).catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
      } else if (!this.unit.panicEnabled) {
        this.$store.dispatch('police/updatePanic', true).catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
      }
    },
    playPanicSound() {
      this.panicAudioSource = new Audio('//cdn.leafcad.com/sounds/panicbutton.mp3')
      this.panicAudioSource.play()

      const panicSoundIntervalFn = setInterval(() => {
        this.panicAudioSource.play()
      }, 32000)
      this.$store.dispatch('police/updatePanicSoundIntervalFn', panicSoundIntervalFn)
    },
    playNewCallSound() {
      this.newCallAudioSource = new Audio('//cdn.leafcad.com/sounds/new-call-police.mp3')
      this.newCallAudioSource.play()
    },
    stopPanicSound() {
      if (this.panicAudioSource) {
        this.panicAudioSource.pause()
      }

      this.panicAudioSource = null
      this.$store.dispatch('police/updatePanicSoundIntervalFn', null)
    },
    openArrestationReport(arrestationId) {
      this.openedArrestationId = arrestationId
      this.$store.dispatch('popup/open', { page: 'police', popup: 'arrestation-report-viewer' })
    },
    openViolationReport(citationId) {
      this.openedCitationId = citationId
      this.$store.dispatch('popup/open', { page: 'police', popup: 'violation-report-viewer' })
    },
    addNoteToCall() {
      this.isCallCardNoteLoading = true
      this.fieldsErrors = {}

      if (!this.callCardNote || (this.callCardNote && this.callCardNote.trim() === '')) {
        this.fieldsErrors.callCardNote = true
        return
      }

      const updatedCallCard = {
        logs: {
          create: {
            message: 'note',
            messageReplacements: {
              sender: `${this.$store.state.selection.character.officer.badgeNumber}`,
              role: 'POLICE',
              note: this.callCardNote,
              unit: this.unit.number,
              extendedBadgeNumber: this.$store.getters['police/officerName']
            }
          }
        }
      }

      this.$store
        .dispatch('dispatcher/updateCallCard', { callCardId: this.call.id, callCard: updatedCallCard })
        .then(response => {
          this.isCallCardNoteLoading = false
          this.callCardNote = ''

          this.$notify({
            type: 'success',
            title: this.$t('notifications.note-sent.title'),
            text: this.$t('notifications.note-sent.text')
          })
        })
    },
    isOwnLog(log) {
      let logOwned = false

      if (log && log.messageReplacements) {
        Object.values(log.messageReplacements).forEach(replacement => {
          if (
            replacement.includes(this.$store.state.selection.character.officer.badgeNumber) ||
            replacement.includes(this.unit.number)
          ) {
            logOwned = true
          }
        })
      }

      return logOwned
    },
    searchCivilian(civilianId) {
      this.$store.dispatch('popup/open', { page: 'police', popup: 'searchPerson' })
      this.$refs.searchPerson.openCivilian(civilianId)
    }
  },
  computed: {
    logs() {
      return this.call ? this.call.callLogs || this.call.logs : []
    }
  },
  watch: {
    unit: {
      handler(newVal, oldVal) {
        if (newVal.activeCall && !this.isInCall) {
          this.isInCall = true
          this.activeTab = 'active_call'
        }
      }
    }
  },
  created() {
    if (
      this.$store.state.selection.character &&
      this.$store.state.selection.character.officer &&
      this.$store.state.selection.unit
    ) {
      this.$store.dispatch('police/unsetOfficerAsLeaving', {
        officerId: this.$store.state.selection.character.officer.id,
        unitId: this.$store.state.selection.unit.id
      })
      // console.log('rejoin unit', this.$store.state.selection.character.officer.id, this.$store.state.selection.unit.id)
    } else {
      this.$router.replace({ name: 'roles' })
    }
  }
}
</script>
