<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <SinglePopup
    :title="$t('unit-popup_component.title')"
    name="unit-popup"
    class="default unit-popup"
    :adaptive="true"
    height="80%"
    top="50%"
    :clickToClose="false"
    :active="active"
    @close="close()"
  >
    <div class="form-box col-pc-12 col-mobile-11 col-margin-auto noselect">
      <div class="form col-pc-10 col-margin-auto uk-margin-small-bottom">
        <div class="badge-number">
          <p class="title col-pc-3 col-smobile-12 col-mdmobile-5 col-lgmobile-4">
            {{ $t('unit-popup_component.officer-number') }}:
          </p>
          <div class="custom-input primary col-pc-9 col-smobile-12 col-mdmobile-7 col-lgmobile-10">
            <input
              type="text"
              v-model="badgeNumber"
              @input="debounceBadgeNumber"
              :placeholder="$t('unit-popup_component.officer-number_placeholder')"
              class="col-pc-12 col-mobile-12 box"
            />
          </div>
        </div>
        <div
          class="unit-number"
          v-if="showVehicle"
        >
          <p class="title col-pc-3 col-smobile-12 col-mdmobile-5 col-lgmobile-4">
            {{ $t('unit-popup_component.unit-number') }}:
          </p>
          <div
            class="search col-pc-9 col-smobile-12 col-mdmobile-7 col-lgmobile-10 col-margin-auto"
            v-click-outside="
              () => {
                showVehicleResults = false
              }
            "
          >
            <div class="searchbar">
              <label
                for="searchbar-unit"
                class="input-icon secondary col-pc-12 col-smobile-12 col-mdmobile-12 col-lgmobile-12"
              >
                <i class="fas fa-search"></i>
                <input
                  :disabled="!badgeNumber"
                  class="box col-all-12"
                  type="text"
                  name="searchbar-unit"
                  :placeholder="$t('unit-popup_component.unit-number_placeholder')"
                  @input="debounceVehicleSearch"
                  @focus="searchVehicleQuery.trim() !== '' ? debounceVehicleSearch() : (showVehicleResults = false)"
                  v-model="searchVehicleQuery"
                />
                <i
                  style="margin: auto;"
                  class="fas fa-circle-notch fa-spin"
                  v-show="isLoading"
                ></i>
              </label>
            </div>
            <div
              class="searchbar-results col-pc-12 col-mobile-12"
              v-if="showVehicleResults"
            >
              <ul>
                <li
                  v-if="
                    canCreateUnits &&
                      searchVehicleQuery.trim() !== '' &&
                      searchVehicleResults.filter(vehicle => vehicle.number === searchVehicleQuery).length === 0
                  "
                  @click="createUnit"
                >
                  {{ $t('unit-popup_component.create-new-vehicle') }}
                  <b>{{ searchVehicleQuery }}</b>
                </li>
                <li v-if="!canCreateUnits && searchVehicleQuery.trim() !== '' && searchVehicleResults.length === 0">
                  {{ $t('unit-popup_component.no-results') }}
                </li>
                <!-- NOTE: VSCode might highlight an error, but it's not. Eslint has been disabled to this specific rule -->
                <li
                  v-for="searchResult in searchVehicleResults"
                  :key="searchResult.id"
                  :class="{ disabled: disableUnit(searchResult) }"
                  @click="disableUnit(searchResult) ? null : joinUnit(searchResult)"
                >
                  <div v-if="
                      ((selectedUnit && searchResult.id !== selectedUnit.id) || !selectedUnit) &&
                        searchResult.seats.length !== searchResult.maxSeats
                    ">
                    {{ $t('unit-popup_component.join-vehicle') }}
                    <b>{{ searchResult.number }}</b>
                  </div>
                  <div v-if="selectedUnit && searchResult.id === selectedUnit.id">
                    <b>{{ searchResult.number }}</b>
                    ({{ $t('unit-popup_component.current-vehicle') }})
                  </div>
                  <div v-if="
                      ((selectedUnit && searchResult.id !== selectedUnit.id) || !selectedUnit) &&
                        searchResult.seats.length === searchResult.maxSeats
                    ">
                    <b>{{ searchResult.number }}</b>
                    ({{ $t('unit-popup_component.vehicle-full') }})
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="top-buttons">
          <div
            class="join-last col-all-12"
            v-if="!unit && lastUnit && showVehicle"
          >
            <div class="button col-all-12 uk-text-center">
              <button
                class="primary box col-all-12"
                @click="joinUnit(lastUnit)"
              >
                {{ $t('unit-popup_component.join-last-vehicle') }} ({{ lastUnit.number }})
              </button>
            </div>
          </div>
          <div
            class="show-all col-all-12 col-mobile-12"
            v-if="showVehicle"
          >
            <div class="button col-all-12 uk-text-center">
              <button
                :disabled="!badgeNumber"
                class="primary box col-all-12"
                @click="
                  () => {
                    // We invert since we want to trigger this when the vehicle list WILL be shown, it's false before it's true
                    if (!showVehicleList) {
                      freshFetchVehicles()
                    }

                    showVehicleList = !showVehicleList
                  }
                "
              >
                {{
                  showVehicleList
                    ? $t('unit-popup_component.hide-all-vehicles')
                    : $t('unit-popup_component.show-all-vehicles')
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="available-vehicles col-pc-10 col-mobile-12 col-margin-auto"
        v-if="showVehicle && showVehicleList"
      >
        <div class="scrollable-list">
          <ul>
            <li
              v-for="vehicle in vehicles"
              :key="vehicle.id"
            >
              <div class="main-content col-f">
                <div class="text col-all-9">
                  <p class="unit">
                    <b>{{ vehicle.number }}</b> <span>({{ vehicle.seats.length }} / {{ vehicle.maxSeats }})</span><span v-if="unit && vehicle.id === unit.id">
                      - {{ $t('unit-popup_component.current-vehicle') }}</span>
                  </p>
                </div>
                <div class="unit-buttons col-all-3 col-f">
                  <button
                    class="delete danger-full col-all-6"
                    :disabled="vehicle.seats.length > 0"
                    @click="deleteUnit(vehicle)"
                    v-if="checkPermissions()(permissions, 'admin')"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                  <button
                    class="enter orange-full col-all-6"
                    :disabled="(unit && vehicle.id === unit.id) || vehicle.seats.length === vehicle.maxSeats"
                    @click="joinUnit(vehicle)"
                  >
                    <i class="fas fa-sign-in-alt"></i>
                  </button>
                </div>
              </div>
            </li>
            <infinite-loading
              :identifier="infiniteIdentifier"
              spinner="spiral"
              @infinite="fetchMoreVehicles"
            >
              <div slot="no-more">
                <p class="primary uk-margin-small-top">{{ $t('infinite-scroll_component.no-more-results') }}</p>
              </div>
              <div slot="no-results">
                <p class="primary uk-margin-small-top">{{ $t('infinite-scroll_component.no-results') }}</p>
              </div>
            </infinite-loading>
          </ul>
        </div>
      </div>
      <div
        class="seats col-pc-10 col-mobile-12 col-margin-auto"
        v-if="unit && showVehicle && !showVehicleList"
      >
        <div class="seats-number col-f">
          <div class="col-pc-6 col-mobile-6">
            <p style="margin: 0;">
              {{ $t('unit-popup_component.seats') }} ({{ unit.seats.length }} / {{ realNumberOfSeats }})
            </p>
          </div>
          <div
            class="col-pc-6 col-mobile-6 uk-text-right"
            v-if="canEditSeats"
          >
            <div class="custom-input primary">
              <input
                type="numeric"
                v-model.number="unit.maxSeats"
                @input="debounceMaxSeats"
                :placeholder="$t('unit-popup_component.max-seats_placeholder')"
                style="width: 50px;text-align: center;"
              />
            </div>
          </div>
        </div>
        <div class="allSeats">
          <table class="col-pc-12 col-mobile-12">
            <tr
              class="seat col-pc-12 col-mobile-12"
              v-for="seatedOfficer in unit.seats"
              :key="seatedOfficer.id"
            >
              <td class="col-pc-4 col-mobile-4">
                <p style="margin: 0;">{{ seatedOfficer.badgeNumber }}</p>
              </td>
              <td class="col-pc-4 col-mobile-4">
                <p
                  style="margin: 0;"
                  :uk-tooltip="
                    checkPermissions()(permissions, 'admin')
                      ? `title: ${seatedOfficer.civilian.creator.email}; cls: uk-active`
                      : `title: ; cls: col-display-none`
                  "
                >
                  {{ `${seatedOfficer.civilian.firstName} ${seatedOfficer.civilian.lastName}` }}
                </p>
              </td>
              <td
                class="col-pc-4 col-mobile-4"
                v-if="selectedCharacter.id === seatedOfficer.civilian.id"
              >
                <a @click="exitUnit">{{ $t('unit-popup_component.actions.exit') }}</a>
              </td>
            </tr>
          </table>
        </div>
        <p
          class="you-are-riding"
          style="margin: 0;"
        >
          <template v-if="unit.seats.length === 1">{{ $t('unit-popup_component.riding.solo') }}</template>
          <template v-if="unit.seats.length === 2">{{ $t('unit-popup_component.riding.duo') }}</template>
          <template v-if="unit.seats.length > 2">
            {{ $t('unit-popup_component.riding.multiple', { count: unit.seats.length - 1 }) }}
          </template>
          <template v-if="unit.seats.length === unit.maxSeats">&nbsp;- {{ $t('unit-popup_component.vehicle-is-full') }}</template>
        </p>
      </div>
      <div
        class="message"
        v-if="showVehicle && !unit && !showVehicleList"
      >
        <h3 v-if="badgeNumber">{{ $t('unit-popup_component.please-join-or-create-a-vehicle') }}</h3>
        <h3 v-if="!badgeNumber">{{ $t('unit-popup_component.please-set-a-badge-number') }}</h3>
      </div>
      <div
        class="message"
        v-if="!showVehicle && !unit && !showVehicleList && !badgeNumber"
      >
        <h3>{{ $t('unit-popup_component.please-set-a-badge-number') }}</h3>
      </div>
      <div
        class="buttons col-pc-10 col-mobile-12 col-margin-auto"
        v-if="unit && showVehicle && !showVehicleList"
      >
        <div class="row col-f">
          <button
            class="primary col-pc-12 col-mobile-12"
            :class="{ active: !unit.onDuty }"
            @click="goOffDuty"
          >
            {{ $t('unit-popup_component.actions.off-duty') }}
          </button>
          <button
            class="primary col-pc-12 col-mobile-12"
            :class="{ active: unit.onDuty }"
            @click="goOnDuty"
          >
            {{ $t('unit-popup_component.actions.on-duty') }}
          </button>
        </div>
        <div class="row col-f">
          <button
            class="primary col-pc-12 col-mobile-12"
            @click="exitUnit"
          >
            {{ $t('unit-popup_component.actions.exit') }} {{ $t('unit-popup_component.title').toLowerCase() }}
          </button>
          <button
            class="primary col-pc-12 col-mobile-12"
            :disabled="!canSubmit"
            @click="save"
          >
            {{ $t('actions.continue') }} / {{ $t('actions.close') }}
          </button>
        </div>
      </div>
      <div
        class="buttons col-pc-12 uk-text-center"
        v-if="!showVehicle"
      >
        <button
          class="primary col-pc-6 col-mobile-6"
          :disabled="!canSubmit"
          @click="save"
        >
          {{ $t('actions.continue') }} / {{ $t('actions.close') }}
        </button>
      </div>
    </div>
  </SinglePopup>
</template>
<style lang="less" scoped src="@/assets/less/components/popup-styles.less"></style>

<script>
import { mapGetters } from 'vuex'
import SinglePopup from '@/components/SinglePopup'
import ClickOutside from 'vue-click-outside'
import { debounce } from 'lodash'
import InfiniteLoading from 'vue-infinite-loading'

const pageSize = 10

export default {
  name: 'UnitPopup',
  components: {
    SinglePopup,
    InfiniteLoading
  },
  props: {
    active: Boolean,
    showVehicle: Boolean,
    exitOnClose: Boolean,
    willRedirectAfterExit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      showVehicleResults: false,
      searchVehicleQuery: '',
      searchVehicleResults: [],
      badgeNumber: '',
      unit: null,
      realNumberOfSeats: null,
      showVehicleList: false,
      vehicles: [],
      vehiclesPage: 0,
      infiniteIdentifier: +new Date()
    }
  },
  computed: {
    selectedCharacter() {
      return this.$store.state.selection.character
    },
    selectedUnit() {
      return this.$store.state.selection.unit
    },
    lastUnit() {
      return this.$store.state.selection.lastUnit
    },
    canSubmit() {
      return this.badgeNumber !== '' && (this.showVehicle ? this.unit : true)
    },
    permissions() {
      return this.$store.state.selection.community ? this.$store.state.selection.community.permissionsValue : 0
    },
    canEditSeats() {
      if (this.$store.state.selection.community) {
        if (this.$store.state.selection.community.community.settings.onlyAdminsCanChangeMaxSeats) {
          if (this.checkPermissions()(this.permissions, 'admin')) {
            return true
          }

          return false
        }
      }

      return true
    },
    canCreateUnits() {
      if (this.$store.state.selection.community) {
        if (this.$store.state.selection.community.community.settings.onlyAdminsCanCreateNewUnits) {
          if (this.checkPermissions()(this.permissions, 'admin')) {
            return true
          }

          return false
        }
      }

      return true
    }
  },
  apollo: {
    unit: {
      query: require('@/graphql/selection/fetchUnit.gql'),
      fetchPolicy: 'network-only',
      variables() {
        return {
          where: { id: this.selectedUnit ? this.selectedUnit.id : '' }
        }
      },
      update(data) {
        if (data.unit && data.unit.maxSeats) {
          this.realNumberOfSeats = data.unit.maxSeats
        }

        return data.unit
      },
      subscribeToMore: {
        document: require('@/graphql/selection/subscribeToUnit.gql'),
        variables() {
          return {
            id: this.selectedUnit ? this.selectedUnit.id : ''
          }
        },
        updateQuery(previousResult, { subscriptionData }) {
          const unit = subscriptionData.data.unit

          if (!unit.node) return

          if (unit.node.maxSeats) {
            this.realNumberOfSeats = unit.node.maxSeats
          }

          if (unit.node.seats.filter(seat => seat.id === this.selectedCharacter.officer.id).length === 0) {
            this.$emit('exitedUnit')
            this.$store.commit('selection/unit', null)
            this.searchVehicleQuery = ''

            this.$notify({
              type: 'warn',
              title: this.$t('notifications.expulsed-from-unit.title'),
              text: this.$t('notifications.expulsed-from-unit.text')
            })
          }

          return {
            unit: subscriptionData.data.unit.node
          }
        }
      }
    },
    vehicles: {
      query: require('@/graphql/selection/fetchAllUnits.gql'),
      fetchPolicy: 'network-only',
      variables() {
        return {
          communityId: this.$store.state.selection.community ? this.$store.state.selection.community.community.id : '',
          offset: 0,
          limit: 0
        }
      },
      update(data) {
        return data.units
      },
      subscribeToMore: {
        document: require('@/graphql/selection/subscribeToAllUnits.gql'),
        variables() {
          return {
            communityId: this.$store.state.selection.community ? this.$store.state.selection.community.community.id : ''
          }
        },
        updateQuery: (previousResult, { subscriptionData }) => {
          const unit = subscriptionData.data.unit

          if (unit.mutation === 'CREATED') {
            return {
              units: [...previousResult.units, unit.node]
            }
          }

          if (unit.mutation === 'UPDATED') {
            return {
              units: previousResult.units.map(mappedUnit => (mappedUnit.id === unit.node.id ? unit.node : mappedUnit))
            }
          }

          if (unit.mutation === 'DELETED') {
            return {
              units: previousResult.units.filter(mappedUnit => mappedUnit.id !== unit.previousValues.id)
            }
          }
        }
      }
    },
    $subscribe: {
      officerUpdated: {
        query: require('@/graphql/dispatcher/subscribeToOfficer.gql'),
        variables() {
          return {
            officerId: this.selectedCharacter.officer ? this.selectedCharacter.officer.id : ''
          }
        },
        result({ data }) {
          const officer = data.officer.node
          console.log(officer)

          if (officer.vehicle && officer.vehicle.id !== this.selectedUnit.id) {
            this.doJoinUnit(officer.vehicle).then(() => {
              this.$store.dispatch('police/finalizeTransferToVehicle', {
                officerId: officer.id
              })
            })
          }
        }
      }
    }
  },
  methods: {
    ...mapGetters('auth', ['checkPermissions']),
    close: function() {
      this.$emit('close')
    },
    disableUnit(unit) {
      return (this.selectedUnit && unit.id === this.selectedUnit.id) || unit.seats.length === unit.maxSeats
    },
    debounceBadgeNumber: debounce(function() {
      let type = 'create'

      if (this.selectedCharacter.officer) {
        type = 'update'
      }

      this.$store
        .dispatch('selection/createOrUpdateOfficerForCurrentCivilian', {
          type: type,
          badgeNumber: this.badgeNumber
        })
        .then(response => {
          this.$store.dispatch('selection/fetchCharacters').then(response => {
            if (this.selectedCharacter) {
              response.forEach(character => {
                if (character.id === this.selectedCharacter.id) {
                  this.$store.commit('selection/character', character)
                }
              })
            }
          })
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    }, 350),
    debounceVehicleSearch: debounce(function() {
      if (this.searchVehicleQuery.trim() === '') {
        this.showVehicleResults = false
        return
      }

      this.isLoading = true
      this.$store
        .dispatch('selection/fetchUnitsWithSimilarNumber', this.searchVehicleQuery.trim())
        .then(response => {
          this.isLoading = false

          this.searchVehicleResults = response
          this.showVehicleResults = true
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    }, 200),
    createUnit() {
      this.$store
        .dispatch('selection/exitUnit')
        .then(response => {
          this.$store
            .dispatch('selection/createUnit', this.searchVehicleQuery)
            .then(response => {
              this.$store.commit('selection/unit', response)
              this.searchVehicleQuery = response.number

              this.showVehicleResults = false
            })
            .catch(error => {
              this.$store.dispatch('errorManager/showError', { error: error })
            })
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    joinUnit(unitToJoin) {
      this.$store
        .dispatch('selection/exitUnit')
        .then(response => {
          this.doJoinUnit(unitToJoin)
        })
        .catch(error => {
          if (error.graphQLErrors) {
            let errors = []
            error.graphQLErrors.map(({ extensions }) => {
              errors = Object.values(extensions)
            })

            if (errors.includes('INVALID_UNIT_ID')) {
              this.$store.commit('selection/unit', null)
              this.doJoinUnit(unitToJoin)
            } else {
              this.$store.dispatch('errorManager/showError', { error: error })
            }
          }
        })
    },
    doJoinUnit(unitToJoin) {
      return new Promise(resolve => {
        if (this.$store.state.selection.character.officer === null) {
          this.$store.dispatch('errorManager/showError', { error: 'MUST_HAVE_A_BADGE_NUMBER' })
          return
        }

        this.$store
          .dispatch('selection/joinUnit', unitToJoin)
          .then(response => {
            this.$emit('joinedUnit')
            this.$store.commit('selection/unit', unitToJoin)
            this.searchVehicleQuery = unitToJoin.number
            this.realNumberOfSeats = unitToJoin.maxSeats

            this.showVehicleResults = false
            this.showVehicleList = false

            resolve()
          })
          .catch(error => {
            this.$store.dispatch('errorManager/showError', { error: error })
          })
      })
    },
    deleteUnit(unitToDelete) {
      this.$swal({
        title: this.$t('unit-popup_component.delete-unit-dialog.title', {
          unitNumber: unitToDelete.number
        }),
        text: this.$t('unit-popup_component.delete-unit-dialog.text', {
          unitNumber: unitToDelete.number
        }),
        type: 'warning',
        focusCancel: true,
        showCancelButton: true,
        confirmButtonText: this.$t('actions.delete')
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch('selection/deleteUnit', unitToDelete)
            .then(response => {
              this.freshFetchVehicles()
            })
            .catch(error => {
              this.$store.dispatch('errorManager/showError', { error: error })
            })
        }
      })
    },
    exitUnit() {
      if (this.willRedirectAfterExit) {
        this.$swal({
          title: this.$t('unload-helper_mixin.dialog.title'),
          text: this.$t('unload-helper_mixin.dialog.text'),
          type: 'warning',
          focusCancel: true,
          showCancelButton: true,
          confirmButtonText: this.$t('unload-helper_mixin.dialog.confirm')
        }).then(result => {
          if (result.value) {
            this.continueExit()
            this.$store.dispatch('police/updatePanicSoundIntervalFn', null)
            this.$store.dispatch('broadcastMessage/updateRepeatIntervalFn', null)
          }
        })
      } else {
        this.continueExit()
      }
    },
    continueExit() {
      this.$store
        .dispatch('selection/exitUnit')
        .then(response => {
          this.$emit('exitedUnit')
          this.$store.commit('selection/unit', null)
          this.searchVehicleQuery = ''
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    goOnDuty() {
      if (this.unit.onDuty) {
        return
      }

      this.$store
        .dispatch('selection/goOnDuty')
        .then(response => {
          this.$emit('wentOnDuty')
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    goOffDuty() {
      if (!this.unit.onDuty) {
        return
      }

      this.$store
        .dispatch('selection/goOffDuty')
        .then(response => {
          this.$emit('wentOffDuty')
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    debounceMaxSeats: debounce(function() {
      if (this.unit.maxSeats >= 1 && this.unit.maxSeats >= this.unit.seats.length) {
        this.$store
          .dispatch('selection/updateUnitMaxSeats', this.unit.maxSeats)
          .then(response => {
            this.realNumberOfSeats = this.unit.maxSeats

            this.$notify({
              type: 'success',
              title: this.$t('notifications.max-seats-updated.title'),
              text: this.$t('notifications.max-seats-updated.text', { count: this.unit.maxSeats })
            })
          })
          .catch(error => {
            this.$store.dispatch('errorManager/showError', { error: error })
          })
      } else {
        this.$notify({
          type: 'error',
          text: this.$t('notifications.max-seats-invalid.text')
        })
      }
    }, 350),
    save() {
      if (this.canSubmit) {
        this.$emit('saved')
      }
    },
    freshFetchVehicles() {
      this.vehiclesPage = 0
      this.$apollo.queries.vehicles.refresh()
      this.infiniteIdentifier++
    },
    fetchMoreVehicles($state) {
      this.$apollo.queries.vehicles.fetchMore({
        variables: {
          offset: this.vehiclesPage * pageSize,
          limit: pageSize
        },
        updateQuery: (existing, incoming) => {
          if (incoming.fetchMoreResult.units.length > 0) {
            $state.loaded()
            this.vehiclesPage++
            return {
              units: [...existing.units, ...incoming.fetchMoreResult.units]
            }
          }

          $state.complete()
        }
      })
    }
  },
  watch: {
    active: {
      handler(value) {
        if (value) {
          this.$modal.show('unit-popup')
          this.justOpenned = true
          if (this.selectedCharacter.officer) {
            this.badgeNumber = this.selectedCharacter.officer.badgeNumber
          } else {
            this.badgeNumber = ''
          }

          if (this.selectedUnit) {
            this.searchVehicleQuery = this.selectedUnit.number
          } else {
            this.searchVehicleQuery = ''
          }
        } else {
          if (this.exitOnClose) {
            this.exitUnit()
          }
          this.$modal.hide('unit-popup')
        }
      }
    }
  },
  mounted() {
    if (this.active) {
      this.$modal.show('unit-popup')
      if (this.selectedCharacter.officer) {
        this.badgeNumber = this.selectedCharacter.officer.badgeNumber
      } else {
        this.badgeNumber = ''
      }

      if (this.unit) {
        this.searchVehicleQuery = this.unit.number
      } else {
        this.searchVehicleQuery = ''
      }
    }
  },
  directives: {
    ClickOutside
  }
}
</script>
