<template>
  <ul class="notes-list">
    <li
      class="no_result"
      v-if="notes.length === 0"
    >
      <p>{{ $t('search-person_component.notes.no-note') }}</p>
    </li>
    <li
      v-for="note in notes"
      :key="note.id"
      class="col-all-12"
    >
      <NoteItem
        ref="notes"
        :note="note"
        :canEdit="note.writtenBy.id === $store.state.selection.character.id || $route.name.includes('dispatch')"
        @save="saveNote()"
        @delete="deleteNote($event)"
      />
    </li>
    <li class="uk-width-1-1">
      <Pagination
        :current="currentPage"
        :total="notesTotal"
        :per-page="notesPerPage"
        @pageChanged="currentPage = $event"
      />
      <button
        class="primary-full uk-width-1-3"
        @click="addNote"
        :disabled="isAddingNote"
      >
        {{ $t('actions.add') }}
      </button>
    </li>
  </ul>
</template>

<script>
import NoteItem from './NoteItem'
import Pagination from '../Pagination'

export default {
  components: { NoteItem, Pagination },
  name: 'NotesSection',
  props: {
    civilianId: {
      type: String
    }
  },
  data() {
    return {
      isLoading: false,
      notes: [],
      notesTotal: 0,
      currentPage: 1,
      notesPerPage: 3
    }
  },
  apollo: {
    notes: {
      query: require('@/graphql/civilian/fetchCivilianNotes.gql'),
      fetchPolicy: 'network-only',
      update(data) {
        this.$apollo.queries.notesTotal.refetch()

        return data.civilianNotes
      },
      variables() {
        return {
          civilianId: this.civilianId,
          skip: this.notesPerPage * (this.currentPage - 1),
          take: this.notesPerPage
        }
      }
    },
    notesTotal: {
      query: require('@/graphql/civilian/fetchCivilianNotes.gql'),
      fetchPolicy: 'network-only',
      update(data) {
        this.$emit('notesCount', data.civilianNotes.length)

        return data.civilianNotes.length
      },
      variables() {
        return {
          civilianId: this.civilianId
        }
      }
    }
  },
  computed: {
    isAddingNote() {
      return this.notes.find(note => note.id === -1)
    }
  },
  methods: {
    forceRefetch() {
      this.$apollo.queries.notes.refetch()
      this.$apollo.queries.notesTotal.refetch()
      this.currentPage = 1

      this.$refs.notes.forEach(note => note.$forceUpdate())
    },
    addNote() {
      if (!this.isAddingNote) {
        this.notes.unshift({
          id: -1,
          text: '',
          writtenBy: {
            connect: { id: this.$store.state.selection.character.id }
          },
          updatedBy: {
            connect: { id: this.$store.state.selection.character.id }
          },
          civilian: {
            connect: { id: this.civilianId }
          }
        })
      }
    },
    saveNote() {
      this.$apollo.queries.notes.refetch()
      this.currentPage = 1
    },
    deleteNote(id) {
      this.notes = this.notes.filter(note => note.id !== id)

      if (this.notes.length === 0) {
        this.currentPage--
      }
      this.$apollo.queries.notes.refetch()
    }
  }
}
</script>
